<template>
    <div>

                <!-- Home Section -->
                <section class="home-section bg-dark-alfa-70 parallax-5" style="background-image: url('img/competition-bw-bg.jpg');"  id="home">
                    <div class="container min-height-100vh d-flex align-items-center pt-100 pb-100">
                        
                        <!-- Hero Content -->
                        <div class="home-content text-start">
                            <div class="row">
                                <div class="col-lg-7">
                                    <h2 class="hs-line-2 mb-10 mb-xs-30 wow fadeInUpShort" data-wow-delay=".2s">The Next Evolution of Online Gaming</h2>
                                    <h2 class="hs-line-3 mb-30 mb-xs-40 wow fadeInUpShort" data-wow-delay=".1s" style="color: #a1a1a1; text-transform: uppercase;">The Ready Up Arena app delivers an online gaming experience that wasn't possible until <i>NOW</i>.</h2>
                                    <div class="local-scroll wow fadeInUpShort" data-wow-delay=".3s">
                                        <a href="#mission" class="btn btn-mod btn-border-w btn-large btn-round mx-md-1">Learn More</a>
                                        <a href="pages-services-1-dark.html" class="btn btn-mod btn-w btn-large btn-round mx-md-1">Sign Up Now for Free</a>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <!-- End Hero Content -->
                        
                        <!-- Scroll Down -->
                        <div class="local-scroll scroll-down-wrap wow fadeInUpShort" data-wow-offset="0">
                            <a href="#mission" class="scroll-down"><i class="scroll-down-icon"></i><span class="sr-only">Scroll to the next section</span></a>
                        </div>
                        <!-- End Scroll Down -->
                        
                    </div>
                </section>
                <!-- End Home Section -->
                
                <!-- Mission Section -->
                <section class="page-section bg-pattern" id="mission">
                    <div class="container relative">
                        <div class="mt-50 mb-80 mb-sm-70">
                            <div class="row section-text">
                            
                                <div class="col-md-12 col-lg-4 mb-md-50 mb-xs-30">
                                    <h2 class="lead-alt wow linesAnimIn" data-splitting="lines">
                                       RUA is a platform that redefines how gamers and fans meet & compete.
                                    </h2>
                                </div>
                                
                                <div class="col-md-6 col-lg-4 mb-sm-50 mb-xs-30 wow linesAnimIn" data-splitting="lines">
                                    Lorem ipsum dolor sit amet conseur adipisci inerene maximus ligula sempe metuse pelente mattis. Maecenas volutpat, diam eni sagittise quame porta quam. Sed id dolor consectetur fermentum volutpat accumsan purus. 
                                </div>
                                
                                <div class="col-md-6 col-lg-4 mb-sm-50 mb-xs-30 wow linesAnimIn" data-splitting="lines">
                                    Etiam sit amet fringilla lacus susantebe sit ullamcorper pulvinar neque porttitor. Integere lectus. Praesent sede nisi eleifend fermum orci amet, iaculis libero. Donec vel ultricies purus. Name dictum sem ipsumebe euliquam.
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <!-- End Mission Section -->
                
                <!-- Divider -->
                <hr class="mt-0 mb-0" />
                <!-- End Divider -->
                
                <!-- Meet Section -->
                <section class="page-section bg-dark bg-dark-alfa-70 bg-scroll light-content" style="background-image: url('img/controller-bw-bg.jpg');">
                    <div class="container relative">
                        
                    <div class="row">
                        <div class=" col-lg-8 offset-lg-2 wow fadeInUpShort">
                            
                            <div class="text-center mb-50 mb-sm-20">
                                <h2 class="section-title">Meet Your New Teammates, Friends, or Next Competition in the Arena</h2>
                            </div>
                            

                                
                                <!-- Slide Item -->
                                <div>
                                        <p>
                                            Phasellus luctus commodo ullamcorper a posuere rhoncus commodo elit aenean congue, risus utaliquam dapibus lorem ipsum dolor. Thanks!
                                        </p>
                                </div>
                                <!-- End Slide Item -->
                                
                            </div>                            

                    </div>
                        
                    </div>
                </section>
                <!-- End Meet Section -->


                <!-- Career Section -->
                <section class="page-section pt-0 pb-0 banner-section dark-content bg-green" style="color: black !important;">
                    <div class="container relative">
                        <div class="row">
                            <div class="col-lg-6 relative">
                                <div class="banner-image-1">
                                    <img src="img/gamer-3.jpg" alt="" class="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="292" />
                                </div>
                                <div class="banner-image-2">
                                    <img src="img/gamer-2.jpg" alt="" class="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="70" />
                                </div>
                            </div>
                            
                            <div class="col-lg-5 offset-lg-1">                                
                                <div class="mt-140 mt-lg-80 mt-md-60 mt-xs-30 mb-140 mb-lg-80">
                                    <div class="banner-content wow fadeInUpShort" data-wow-duration="1.2s">
                                        <h3 class="banner-heading">Looking to start an online gaming career?</h3>
                                        <div class="banner-decription">
                                            Whether you are an aspiring gamer, streamer, referee, or shoutcaster, RUA can help you meet other aspiring professionals and 
                                            gain exposure while earning rewards as you grow.
                                         </div>
                                    </div>
                                </div>                             
                            </div>
                        </div>
                    </div>
                </section>
                <!-- End Career Section -->

                 <!-- Platforms Section -->
                <section class="page-section" id="platforms" style="background-image: url('img/vr-bg-1.jpg');" >
                    <div class="container relative">
                        
                        <div class="text-center mb-80 mb-sm-50">
                            <h2 class="section-title">Take Gaming Into the <i>Metaverse</i></h2>
                            <p class="section-title-descr">
                                No, seriously.  The latest technolgoy has enabled gamers from around the world to play and experience the Metaverse together.
                                Ready Up Arena adds an expanded layer to allow them to connect, play, wager, and track their progress across platforms. 
                            </p>
                        </div>
                        
                        <!-- Grid -->
                        <div class="row services-grid">
                            
                            <!-- Item -->
                            <div class="col-sm-6 col-md-4 col-lg-4">
                                <div class="services-item text-center wow fadeIn" data-wow-delay="0" data-wow-duration="1.5s">
                                    <div class="services-icon">
                                       <img src="img/oculus-logo.png">
                                       <img src="img/steam-logo.png">
                                    </div>
                                    <h3 class="services-title">Virtual Reality</h3>
                                    <div class="services-descr">
                                        Virtual Reality is changing the face of gaming to add a whole new dimension of immersion and connection between players in the Metaverse.
                                    </div>
                                </div>
                            </div>
                            <!-- End Item -->

                           <!-- Item -->
                            <div class="col-sm-6 col-md-4 col-lg-4">
                                <div class="services-item text-center wow fadeIn" data-wow-delay="0" data-wow-duration="1.5s">
                                    <div class="services-icon">
                                       <img src="img/windows-logo.png">
                                       <img src="img/playstation-logo.png">
                                       <img src="img/xbox-logo.png">
                                       <img src="img/switch-logo.png">
                                    </div>
                                    <h3 class="services-title">Console and PC</h3>
                                    <div class="services-descr">
                                        We also support all major PC and console platforms that appeal to modern gamers to ensure that no matter what platform, you can find your next team or opponent.
                                    </div>
                                </div>
                            </div>
                            <!-- End Item -->

                            <!-- Item -->
                            <div class="col-sm-6 col-md-4 col-lg-4">
                                <div class="services-item text-center wow fadeIn" data-wow-delay="0" data-wow-duration="1.5s">
                                    <div class="services-icon">
                                       <img src="img/android-logo.png">
                                       <img src="img/apple-logo.png">
                                    </div>
                                    <h3 class="services-title">Mobile</h3>
                                    <div class="services-descr">
                                        Online gaming has expanded into mobile platforms and RUA has support for the hottest online mobile titles for competitive and cooperative play.
                                    </div>
                                </div>
                            </div>
                            <!-- End Item -->
                        </div>
                        <!-- End Services Grid -->
                        
                    </div>
                </section>
                <!-- End Platforms Section -->

                <!-- Games Section -->
                <section class="page-section pb-80 bg-pattern" id="games">
                    <div class="full-wrapper relative">
                        
                        <div class="text-center mb-80 mb-sm-50">
                            <h2 class="section-title mb-10 mb-sm-4">Supported Games</h2>
                            <p class="section-title-descr">
                                Ready Up Arena supports a wider variety of games and platforms than any other online gaming matchmaking service. We are constantly adding new games and content, 
                                listening to our players on which games they want to see next.
                            </p>
                        </div>

                        <!-- Works Grid -->
                        <ul class="works-grid work-grid-3 work-grid-gut clearfix hover-white hide-titles" id="work-grid">
                            
                            <!-- Work Item (Lightbox) -->
                            <li class="work-item mix photography">
                                <a class="work-lightbox-link mfp-image">
                                    <div class="work-img">
                                        <div class="work-img-bg wow-p scalexIn"></div>
                                        <img src="https://cdn.rua.gg/titles/gametitle-1.png" alt="Work Description" class="wow-p fadeIn" data-wow-delay="1s" />
                                    </div>
                                    <div class="work-intro">
                                        <h3 class="work-title">Green Leaf</h3>
                                        <div class="work-descr">
                                            Lightbox 
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <!-- End Work Item -->
                            
                            <!-- Work Item (External Page) -->
                            <li class="work-item mix branding design">
                                <a class="work-ext-link">
                                    <div class="work-img">
                                        <div class="work-img-bg wow-p scalexIn"></div>
                                        <img src="https://cdn.rua.gg/titles/gametitle-2.png" alt="Work Description" class="wow-p fadeIn" data-wow-delay="1s" />
                                    </div>
                                    <div class="work-intro">
                                        <h3 class="work-title">Photo Lighting</h3>
                                        <div class="work-descr">
                                            External Page
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <!-- End Work Item -->
                            
                            <!-- Work Item (External Page) -->
                            <li class="work-item mix branding">
                                <a class="work-ext-link">
                                    <div class="work-img">
                                        <div class="work-img-bg wow-p scalexIn"></div>
                                        <img src="https://cdn.rua.gg/titles/gametitle-3.png" alt="Work Description" class="wow-p fadeIn" data-wow-delay="1s" />
                                    </div>
                                    <div class="work-intro">
                                        <h3 class="work-title">Green Branch</h3>
                                        <div class="work-descr">
                                            External Page
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <!-- End Work Item -->
                            
                            <!-- Work Item (External Page) -->
                            <li class="work-item mix design photography">
                                <a class="work-ext-link">
                                    <div class="work-img">
                                        <div class="work-img-bg wow-p scalexIn"></div>
                                        <img src="https://cdn.rua.gg/titles/gametitle-4.png" alt="Work Description" class="wow-p fadeIn" data-wow-delay="1s" />
                                    </div>
                                    <div class="work-intro">
                                        <h3 class="work-title">White Chair</h3>
                                        <div class="work-descr">
                                            External Page
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <!-- End Work Item -->
                            
                            <!-- Work Item (External Page) -->
                            <li class="work-item mix design">
                                <a class="work-ext-link">
                                    <div class="work-img">
                                        <div class="work-img-bg wow-p scalexIn"></div>
                                        <img src="https://cdn.rua.gg/titles/gametitle-5.png" alt="Work Description" class="wow-p fadeIn" data-wow-delay="1s" />
                                    </div>
                                    <div class="work-intro">
                                        <h3 class="work-title">White Table</h3>
                                        <div class="work-descr">
                                            External Page
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <!-- End Work Item -->
                            
                            <!-- Work Item (Lightbox) -->
                            <li class="work-item mix design branding">
                                <a  class="work-lightbox-link mfp-image">
                                    <div class="work-img">
                                        <div class="work-img-bg wow-p scalexIn"></div>
                                        <img src="https://cdn.rua.gg/titles/gametitle-6.png" alt="Work Description" class="wow-p fadeIn" data-wow-delay="1s" />
                                    </div>
                                    <div class="work-intro">
                                        <h3 class="work-title">The Book</h3>
                                        <div class="work-descr">
                                            Lightbox
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <!-- End Work Item -->

                            
                        </ul>
                        <!-- End Works Grid -->
                    </div>
                </section>
                <!-- End Games Section -->

                 <!-- Tournament League Section -->
                <section id="features" class="page-section" style="background-image: url('img/competition-bw-bg.jpg');">
                    <div class="container relative">
                        
                        <!-- Grid -->
                        <div class="row">
                            
                            <!-- Text Item -->
                            <div class="col-md-12 col-lg-3">
                                <div class="align-left">
                                    <h2 class="work-process-heading mt-0">Create Your Own Online Matches, Tournaments, and Leagues</h2>
                                    <p class="work-process-description">
                                        Lorem ipsum dolor sit amet casume adipisin elit. In maximus ligula semper metus pellentesque mattis. Maecenas volutpat, diam enim.
                                    </p>
                                </div>
                            </div>
                            <!-- End Text Item -->
                       </div>
                       <!-- End Grid -->
                        
                    </div>
                </section>
                <!-- Tournament League Section -->

                 <!-- Stream Section -->
                <section class="page-section bg-dark bg-dark-alfa-70 light-content">
                    <div class="container relative">
                        
                    <div class="row">
                        <div class=" col-lg-8 offset-lg-2 wow fadeInUpShort">
                            
                            <div class="text-center mb-50 mb-sm-20">
                                <h2 class="section-title">Get Pro Event Level Online Promotion and Production</h2>
                            </div>
                            

                                
                                <!-- Slide Item -->
                                <div>
                                        <p>
                                            Phasellus luctus commodo ullamcorper a posuere rhoncus commodo elit aenean congue, risus utaliquam dapibus lorem ipsum dolor. Thanks!
                                        </p>
                                </div>
                                <!-- End Slide Item -->
                                
                            </div>                            

                    </div>
                        
                    </div>
                </section>
                <!-- End Streaming Section -->


                <!-- Rewards Section -->
                <section class="page-section bg-dark bg-dark-alfa-70 bg-scroll light-content" style="background-image: url('img/nft-bg.jpg');">
                    <div class="container relative">
                        
                    <div class="row">
                        <div class=" col-lg-8 offset-lg-2 wow fadeInUpShort">
                            
                            <div class="text-center mb-50 mb-sm-20">
                                <h2 class="section-title">Wager and Win Rewards</h2>
                            </div>
                            

                                
                                <!-- Slide Item -->
                                <div>
                                        <p>
                                            Even if you aren't a gamer, you can still participate and win!  Phasellus luctus commodo ullamcorper a posuere rhoncus commodo elit aenean congue, risus utaliquam dapibus lorem ipsum dolor. Thanks!
                                        </p>
                                </div>
                                <!-- End Slide Item -->
                                
                            </div>                            

                    </div>
                        
                    </div>
                </section>
                <!-- End Rewards Section -->

                <!-- Participate Section -->
                <section id="about" class="page-section  bg-pattern">
                    <div class="container relative">
                        
                        <!-- Grid -->
                        <div class="row">
                            
                            <!-- Text Item -->
                            <div class="col-md-12 col-lg-3">
                                <div class="align-left">
                                    <h2 class="work-process-heading mt-0">About Us / RUA</h2>
                                    <p class="work-process-description">
                                        Lorem ipsum dolor sit amet casume adipisin elit. In maximus ligula semper metus pellentesque mattis. Maecenas volutpat, diam enim.
                                    </p>
                                </div>
                            </div>
                            <!-- End Text Item -->
                       </div>
                       <!-- End Grid -->
                        
                    </div>
                </section>
                <!-- Participate Section -->
                
                <!-- Signup Section --> 
                <section id="signup" class="page-section" style="background-image: url('img/arena-bw-bg.png');">
                    <div class="container relative">
                        
                        <form id="mailchimp" class="form wow fadeInUpShort">
                            <div class="row">
                                <div class="col-md-8 offset-md-2">
                                    
                                    <div class="mb-50 mb-sm-20">
                                        <h2>
                                            Sign Up For the Public Beta 
                                        </h2>
                                    </div>
                                    
                                    <div class="d-sm-flex justify-content-between mb-20">
                                        <input placeholder="Your Email" class="newsletter-field input-lg round" type="email" pattern=".{5,100}" required aria-required="true">
                                        <button type="submit" aria-controls="subscribe-result" class="newsletter-button btn btn-mod btn-w btn-large btn-round">
                                            Sign-Up Now
                                        </button>
                                    </div>
                                    
                                    <div class="form-tip">
                                        Proin fringilla augue at maximus vestibulum <a href="#">Terms & Conditions</a>.
                                    </div>
                                    
                                    <div id="subscribe-result" role="region" aria-live="polite" aria-atomic="true"></div>
                                    
                                </div>
                            </div>
                        </form>
                        
                    </div>
                </section>
                <!-- End Signup Section -->
    </div>
</template>
