<template>
        <!-- Page Loader -->        
        <div class="page-loader dark">
            <div class="loader"></div>
        </div>
        <!-- End Page Loader -->

        <!-- Skip to Content -->
        <a href="#main" class="btn skip-to-content">Skip to Content</a>
        <!-- End Skip to Content -->
        
        <!-- Page Wrap -->
        <div class="page bg-dark light-content" id="top">
            
        <!-- Navigation panel -->
        <nav class="main-nav dark transparent stick-fixed wow-menubar" style="display:none;">
            <div class="full-wrapper relative clearfix">
                    
                    <!-- Logo ( * your text or image into link tag *) -->
                    <div class="nav-logo-wrap local-scroll" style="min-height:70px; margin:0px !important; padding:0px !important;">
                        <a href="/" class="logo">
                            <img src="img/rua-icon.png" alt="RUA Logo" class="drop-shadow" style="margin-left:10px; max-width:40px;"/>
                        </a>
                    </div>
                    
                    <!-- Mobile Menu Button -->
                    <div class="mobile-nav" role="button" tabindex="0">
                        <i class="fa fa-bars"></i>
                        <span class="sr-only">Menu</span>
                    </div>
                    
                    <!-- Main Menu -->
                    <div class="inner-nav desktop-nav">
                        <ul class="clearlist scroll-nav local-scroll">
                            <!--<li><router-link to="/features">Features</router-link></li>
                            <li><router-link to="/platforms">Platforms and Games</router-link></li>
                            <li><router-link to="/faq">FAQ</router-link></li>
                            <li class="active"><a class="green" href="#sign">Sign-Up Now</a></li>
                            <li class="active" style="display:none;"><a class="green" href="intro-dark.html">Login</a></li>-->
                        </ul>
                    </div>
                    <!-- End Main Menu -->
                    
                </div>
            </nav>
            <!-- End Navigation panel -->
            
            <main id="main">    
              <router-view></router-view>
            </main>
            
            <!-- Footer -->
            <footer class="page-section bg-pattern light-content footer pt-40 pb-100 pb-sm-50">
                <div class="container">
                    
                    <!-- Social Links -->
                    <div class="footer-social-links mb-20 mb-xs-40">
                        <a href="https://twitter.com/readyuparena" title="Twitter" target="_blank"><i class="fab fa-twitter"></i> <span class="sr-only">Twitter profile</span></a>
                        <a href="https://www.twitch.tv/readyuparena" title="Twitch" target="_blank"><i class="fab fa-twitch"></i> <span class="sr-only">Twitch profile</span></a>
                        <a href="https://www.youtube.com/channel/UCB2QDkw6-gf05R0OCVmLWMw" title="You Tube" target="_blank"><i class="fab fa-youtube"></i> <span class="sr-only">YouTube profile</span></a>
                        <a href="mailto:hello@rua.gg" title="Email" target="_blank"><i class="fa fa-envelope"></i> <span class="sr-only">Email link</span></a>
                    </div>
                    <!-- End Social Links -->

                    <!-- Footer Text -->
                    <div class="footer-text">    
                        <div class="footer-made mt-30">
                            All third party trademarks (including logos and icons) referenced by RUA remain the property of their respective owners. 
                            Unless specifically identified as such, RUA’s use of third party trademarks does not indicate any relationship, sponsorship, 
                            or endorsement between RUA and the owners of these trademarks. Any references by RUA to third party trademarks is to identify 
                            the corresponding third party goods and/or services.
                        </div>
                        <!-- Copyright -->
                        <div class="footer-copy pt-4">
                            ©{{getDate()}} Bitplay, LLC, All Rights Reserved
                        </div>
                        <!-- End Copyright -->
                    </div>
                    <!-- End Footer Text --> 
                    
                 </div>
                 
                 <!-- Top Link -->
                 <div class="local-scroll">
                     <a href="#top" class="link-to-top"><i class="link-to-top-icon"></i></a>
                 </div>
                 <!-- End Top Link -->
                 
            </footer>
            <!-- End Footer -->
        
        </div>
        <!-- End Page Wrap -->        
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin: 0px;
  background: black !important;
  overflow-x: hidden;
}

a{
    font-weight: bold;
}

</style>

<script>
export default {
  name: 'App',
  components: {

  },
  methods:{
      getDate(){
          return new Date().getFullYear();
      },
      moveUp(){
          window.scrollTo(0,0);
      }
  }
}
</script>


